



















































import Base from '@/mixins/Base.vue';
import { ISpObjectResponse } from '@/interfaces/spobject';

const component = Base.extend({

    data() {
        return {
            code: null,
            checking: true,
            active: false,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        checkCode(code: string): void {
            this.checking = false;

            this.post<ISpObjectResponse>('objects/scan', {
                code,
            }).then(({ data }) => {
                if (data.data) {
                    this.$router.push({ name: 'objects.edit', params: { id: data.data.id.toString() } });
                } else {
                    this.$buefy.dialog.alert({
                        message: 'Object not found',
                        onConfirm: () => {
                            this.checking = true;
                        },
                    });
                }
            });
        },
    },
});

export default component;
